import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import {addDoc, collection, doc, setDoc} from "firebase/firestore";
import Footer from "./Footer";
import {BUSINESS_TYPE, isMobileEmail} from "./common";

const CorpRegisterForm = () => {
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [storeName, setStoreName] = useState("");
    const [sei, setSei] = useState("");
    const [mei, setMei] = useState("");
    const name = sei + mei;
    const [seiKana, setSeiKana] = useState("");
    const [meiKana, setMeiKana] = useState("");
    const kana = seiKana + meiKana;
    const [zipcode, setZipcode] = useState("");
    const [addressOrg, setAddressOrg] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [businessTypeList, setBusinessTypeList] = useState([]); // 業態 0:未指定 1:オンラインオリパ事業者 2:海外輸出事業者 3:カードショップ事業者 4:EC事業者 5:その他 6:無回答
    const [isReceivingAddress, setIsReceivingAddress] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [mailCheckMsg, setMailCheckMsg] = useState("");

    const handleChange = async (event) => {
        const inputValue = event.target.value;
        setZipcode(inputValue);

        // 7桁の郵便番号が入力されたらAPIを呼び出す
        if (inputValue.length === 7 && /^\d{7}$/.test(inputValue)) {
            setIsReceivingAddress(true);
            try {
                const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${inputValue}`);
                const data = await response.json();

                if (data.status === 200 && data.results) {
                    const adr = data.results[0];
                    setAddress(`${adr['address1']}${adr['address2']}${adr['address3']}`);
                    setAddressOrg(`${adr['address1']}${adr['address2']}${adr['address3']}`);
                } else {
                    setError('該当する郵便番号は見つかりませんでした。');
                    setAddress('');
                    setAddressOrg('');
                }
            } catch (error) {
                setError('エラーが発生しました。もう一度試してください。');
                setAddress('');
                setAddressOrg('');
            } finally {
                setIsReceivingAddress(false);
            }
        } else {
            setAddress('');
            setAddressOrg('');
        }
    };

    const checkRequired = (value, name) => {
        if (!value) {
            setError(name + "を入力してください");
            setIsSubmitting(false);
            return true;
        }
        return false;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        let err_code = "001";
        let createdAt = "";
        let userData = {};

        try {
            createdAt = new Date().toISOString();
            let businessType = "0";
            if (0 < businessTypeList.length) {
                businessType = businessTypeList.join(",");
            }

            if (checkRequired(email, "メールアドレス")) {
                return;
            }
            if (checkRequired(password, "パスワード")) {
                return;
            }
            if (checkRequired(storeName, "法人名")) {
                return;
            }
            if (checkRequired(name, "担当者名")) {
                return;
            }
            if (checkRequired(kana, "担当者名(カナ)")) {
                return;
            }
            if (checkRequired(address, "住所")) {
                return;
            }
            if (checkRequired(phone, "電話番号")) {
                return;
            }
            if (addressOrg === address) { // 住所
                setError("住所の番地が入力されていません");
                setIsSubmitting(false);
                return;
            }
            // ユーザー登録
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            err_code = "002";
            err_code = "003";
            err_code = "004";

            // Firestoreにユーザーデータを保存
            err_code = "005";
            err_code = "006";

            const gender = "";
            const birthDate = "";
            const job = "";
            const idVerifyDocs = "";
            const invoiceIssuer = "";
            const invoiceIssuerNumber = "";
            const bankName = "";
            const bankBranchName = "";
            const bankBranchCode = "";
            const bankAccountNumber = "";
            const bankAccountName = "";
            const userType = "userTypeCorp"; // userTypeInd: 個人, userTypeCorp: 法人
            const retailerGr = "1"; // 卸グループ 0:未加入 1:加入
            userData = {
                email,
                name,
                kana,
                gender,
                birthDate,
                zipcode,
                address,
                phone,
                job,
                idVerifyDocs,
                createdAt: createdAt,
                updatedAt: createdAt,
                userType,
                invoiceIssuer,
                invoiceIssuerNumber,
                idDocUrl1: "",
                idDocUrl2: "",
                idDocUrl3: "",
                bankName,
                bankBranchName,
                bankBranchCode,
                bankAccountNumber,
                bankAccountName,
                storeName,
                retailerGr,
                businessType,
            };
            err_code = "007";
            err_code = "008";

            await setDoc(doc(db, "users", user.uid), userData);
            err_code = "009";

            // メール確認完了画面に続行ボタンを表示、そのままログインできるようにする
            // const actionCodeSettings = {
            //     url: 'https://shinsoku-tcg.com/'
            // };
            // await sendEmailVerification(user, actionCodeSettings); // メール確認メールを送信
            await sendEmailVerification(user); // メール確認メールを送信
            err_code = "010";

            navigate("/");
        } catch (err) {
            try {
                const errorData = {
                    where: "CorpRegisterForm",
                    createdAt: createdAt,
                    email: email,
                    userData: JSON.stringify(userData),
                    errorMessage: err.message || "不明なエラーが発生しました",
                    errCode: err_code
                };
                await addDoc(collection(db, "errors"), errorData);
            } catch (err2) {
                try {
                    console.log("エラー", err2);
                } catch (err3) {
                }
            }
            if (err.message && err.message.indexOf("email-already-in-use") !== -1) {
                setError("["+err_code+"] 登録に失敗しました。恐れ入りますが、このメッセージをスタッフにお見せください。(このメールアドレスは既に使われています)");
                console.log("["+err_code+"] 登録に失敗しました。恐れ入りますが、このメッセージをスタッフにお見せください。(このメールアドレスは既に使われています)");
            } else {
                setError("["+err_code+"] 登録に失敗しました。恐れ入りますが、このメッセージをスタッフにお見せください。(" + (err.message || "不明なエラーが発生しました") + ")");
                console.log("["+err_code+"] 登録に失敗しました。恐れ入りますが、このメッセージをスタッフにお見せください。(" + (err.message || "不明なエラーが発生しました") + ")");
            }
            setIsSubmitting(false);
        }
    };

    const handleBusinessTypeChange = (event, value) => {
        if (event.target.checked) {
            setBusinessTypeList([...businessTypeList, value]);
        } else {
            setBusinessTypeList(businessTypeList.filter((type) => type !== value));
        }
    };

    return (
        <div>
            <form>
                <div className="container mt-5" style={{maxWidth: "800px"}}>
                    <h5 style={{textAlign: 'center', marginBottom: '20px'}}>シンソクトレカ卸グループ申し込み</h5>
                    <ol>
                        <li>本申し込み後、弊社所定の審査がございます。（審査期間の目安：即日から3営業日以内）</li>
                        <li>審査を通過された場合にのみ、ご登録のメールアドレスにグループの招待リンクをお送りいたします。</li>
                        <li>すでにシンソクの買取会員であるお客様は、別のメールアドレスでお申し込みください。</li>
                    </ol>
                </div>
                <div className="container mt-5" style={{maxWidth: "800px"}}>
                    <table className="table table-bordered" style={{width:"100%", maxWidth:"800px"}}>
                        <tbody>
                        <tr>
                            <th className="required">メールアドレス</th>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => {
                                        setMailCheckMsg("");
                                        if (isMobileEmail(e.target.value)) {
                                            setMailCheckMsg("このメールアドレスは当店からのメールアドレスを受信できない可能性がある為、可能であればPCメールアドレスをご入力いただくようお願い致します。");
                                        }
                                        setEmail(e.target.value);
                                    }}
                                    placeholder="メールアドレスを入力"
                                    required={true}
                                    style={{width: "100%"}}
                                    autoComplete={"username"}
                                />
                                <span style={{color: "red"}}>{mailCheckMsg}</span>
                                <span>迷惑メールの対策などでドメイン指定を行っている場合、メールが受信できない場合がございます。「@shinsoku-tcg.com」を
                                    <a href="/maildomain" target="_blank">受信設定してください。</a>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th className="required">パスワード</th>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="パスワードを入力"
                                    required={true}
                                    style={{width: '100%'}}
                                    autoComplete={"new-password"}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="w-25 required">法人名</th>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type="text"
                                    value={storeName}
                                    onChange={(e) => setStoreName(e.target.value)}
                                    placeholder="例:○○株式会社"
                                    required={true}
                                    style={{width: '100%'}}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="w-25">業態</th>
                        </tr>
                        <tr>
                            <td>
                                {BUSINESS_TYPE.map((type) => (
                                    <label key={type[0]}>
                                        <input
                                            type="checkbox"
                                            value={type[0]}
                                            checked={businessTypeList.includes(type[0])}
                                            onChange={(e) => handleBusinessTypeChange(e, type[0])}
                                            style={{marginLeft: '10px'}}
                                        />
                                        {type[1]}
                                    </label>
                                ))}
                            </td>
                        </tr>
                        <tr>
                            <th className="w-25 required">担当者名</th>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type="text"
                                    value={sei}
                                    onChange={(e) => setSei(e.target.value)}
                                    placeholder="例:山田"
                                    required={true}
                                    style={{width: '50%'}}
                                />
                                <input
                                    type="text"
                                    value={mei}
                                    onChange={(e) => setMei(e.target.value)}
                                    placeholder="例:太郎"
                                    required={true}
                                    style={{width: '50%'}}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="required">担当者名(カナ)</th>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type="text"
                                    value={seiKana}
                                    onChange={(e) => setSeiKana(e.target.value)}
                                    placeholder="例:ヤマダ"
                                    required={true}
                                    style={{width: '50%'}}
                                />
                                <input
                                    type="text"
                                    value={meiKana}
                                    onChange={(e) => setMeiKana(e.target.value)}
                                    placeholder="例:タロウ"
                                    required={true}
                                    style={{width: '50%'}}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="required">住所</th>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type="text"
                                    pattern={"[0-9]+"}
                                    value={zipcode}
                                    onChange={handleChange}
                                    placeholder="郵便番号を入力"
                                    maxLength={7}
                                    required={true}
                                />※ハイフンなし
                                <br></br>
                                <input
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    placeholder="住所を入力"
                                    maxLength={200}
                                    style={{width: '100%', marginTop: '10px'}}
                                    required={true}
                                    disabled={isReceivingAddress}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="required">電話番号</th>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type="text"
                                    pattern={"[0-9]+"}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="電話番号を入力"
                                    maxLength={15}
                                    required={true}
                                />※ハイフンなし
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{textAlign: 'center'}}>
                    <p>申し込みには、<a href="/kiyaku" target="_blank">利用規約</a>および<a href="/privacypolicy" target="_blank">プライバシーポリシー</a>への同意が必要です。</p>
                    {error && <p style={{color: "red", fontWeight: "bold"}}>{error}</p>}
                    <br/>
                    <button
                        className="btn btn-primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    >同意して申し込む
                    </button>
                </div>
            </form>
            <Footer/>
        </div>
    );
};

export default CorpRegisterForm;
